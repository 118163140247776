<template>
  <div class="nav">
    <div class="nav__btn" @click="toggleNav()">
      <div class="nav__toggle" :class="{ 'nav__toggle--active': navOpen }">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div class="nav-modal" :class="{ 'nav-modal--active': navOpen }">
      <div class="nav-modal__inner">
        <div class="container">
          <div class="nav-modal__inner">
            <div class="nav-modal__main">
              <slot></slot>
            </div>
            <div class="nav-info">
              <div class="nav-info__inner row">
                <div class="nav-info__item">
                  <div class="nav-info__headline">
                    <span class="icon-phone"></span>
                    Telefon
                  </div>
                  <div class="nav-info__content">
                    <p>
                      05231 88210
                    </p>
                  </div>
                </div>
                <div class="nav-info__item">
                  <div class="nav-info__headline">
                    <span class="icon-location"></span>
                    Adresse
                  </div>
                  <div class="nav-info__content">
                    <p>
                      W. Rütten + J. Westphal<br />
                      Theodor-Heuss-Str. 1<br />
                      32760 Detmold
                    </p>
                  </div>
                </div>
                <div class="nav-info__item">
                  <div class="nav-info__headline">
                    <span class="icon-envelope"></span>
                    E-Mail schreiben
                  </div>
                  <div class="nav-info__content">
                    <p>
                      <a href="mailto:mail@die-docs.de">mail@die-docs.de</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="nav-benefits">
                <div class="nav-benefits__inner">
                  <div class="nav-benefits__item">
                    <span class="icon-wheelchair"></span>
                    <span class="nav-benefits__text">
                      Barrierefrei
                    </span>
                  </div>
                  <div class="nav-benefits__item">
                    <span class="icon-mortar"></span>
                    <span class="nav-benefits__text">
                      Apotheke
                    </span>
                  </div>
                  <div class="nav-benefits__item">
                    <span class="icon-parking"></span>
                    <span class="nav-benefits__text">
                      Parkplätze
                    </span>
                  </div>
                  <div class="nav-benefits__item">
                    <span class="icon-bus"></span>
                    <span class="nav-benefits__text">
                      öffentl. Verkehrsmittel
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "./ButtonComponent.vue";
import HeadlineComponent from "./HeadlineComponent.vue";
export default {
  components: {
    HeadlineComponent,
    ButtonComponent,
  },
  data() {
    return {
      navOpen: false,
    };
  },
  mounted() {
    this.$eventHub.$on("hoursOpen", () => {
      this.navOpen = false;
    });
    this.$eventHub.$on("scrolling", () => {
      this.navOpen = false;
    });
    this.$eventHub.$on("triggerClick", () => {
      this.navOpen = false;
    });
  },
  methods: {
    toggleNav() {
      this.navOpen = !this.navOpen;
      this.$eventHub.$emit("navOpen");
    },
  },
};
</script>

<style lang="scss">
.nav {
  @apply bg-brown;
  transition: all 0.3s ease-in-out;
  @apply flex;
  @apply items-center;
  @apply justify-center;

  p {
    @apply mb-0;
  }
}

.nav__btn {
  @apply p-4;
  @apply cursor-pointer;

  @screen md {
    @apply p-8;
  }
}

.nav__toggle {
  transition: all 0.1s ease-in-out;
  @apply relative;
  @apply z-50;

  span {
    @apply w-4;
    height: 2px;
    @apply bg-white;
    @apply mb-1;
    @apply block;
  }

  &--active {
    transform: rotate(-45deg);

    span {
      @apply w-4;
    }

    span:nth-child(2) {
      @apply hidden;
    }

    span:nth-child(3) {
      transform: rotate(-90deg) translate3d(6px, 0, 0);
    }
  }
}

.nav-modal {
  @apply bg-brown;
  @apply absolute;
  top: 100%;
  right: 0;
  width: 100vw;
  height: calc(100vh - 62px);
  @apply overflow-hidden;
  max-height: 0;
  @apply text-white;
  @apply justify-center;
  @apply flex;
  transition: all 0.3s ease-in-out;

  @screen md {
    height: calc(100vh - 94px);
  }

  &--active {
    max-height: 100vh;
    @apply overflow-auto;
  }

  @screen lg {
    @apply items-center;
  }
}

.nav-modal__inner {
  @apply py-8;
}

.nav-modal__main {
  @apply flex-wrap;

  @screen lg {
    @apply flex;
  }
}

.nav-modal__main-item {
  @apply text-center;
  @apply mb-8;
  @apply block;

  @screen lg {
    @apply mb-0;
    @apply w-1/3;
    @apply px-12;

    & + & {
      @apply border-l-2;
    }
  }

  .subline {
    font-size: clamp(18px, 2vw, 25px);
  }

  h3 {
    @apply hidden;

    @screen md {
      @apply block;
    }
  }
}

.nav-info {
  padding: 30px 0;

  @screen md {
    padding: 10vh 0;
  }
}

.nav-info__inner {
  @apply flex;
  @apply w-full;
}

.nav-info__item {
  @apply text-center;
  @apply p-4;
  @apply w-full;

  @screen md {
    @apply w-1/3;
  }
}

.nav-info__headline {
  @apply font-serif;
  @apply italic;
  @apply font-bold;
  // @apply text-lg;
  @apply mb-2;
  // @apply hidden;

  // @screen md {
  //   @apply block;
  // }

  span {
    @apply mr-1;
  }
}

.nav-benefits__inner {
  @apply flex;
  @apply items-center;
  @apply w-full;
  @apply justify-center;
  @apply py-4;
}

.nav-benefits__item {
  @apply p-4;
  @apply font-serif;
  @apply italic;
  @apply inline-block;
  @apply text-center;

  & + & {
    @apply border-l;
    @apply border-white;
  }
}

.nav-benefits__text {
  @apply hidden;

  @screen md {
    @apply block;
  }
}
</style>
