<template>

</template>

<script>
    export default {
        props: {
            image: {
                default: '',
                typeo: String
            }
        }
    }
</script>

<style lang="scss" scoped>
   
</style>