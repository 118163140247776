<template>
  <div>Ich bin eine Testkomponente</div>
</template>

<script>
    export default {
        name: 'testComponent',
        mounted() {
            console.log(this)
        }
    }
</script>

<style>

</style>