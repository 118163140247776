<template>
  <div class="doctor">
    <div class="doctor__image">
      <img loading="lazy" :src="image" alt="" />
    </div>
    <div class="doctor__name">{{ name }}</div>
    <div class="doctor__content" v-html="content"></div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      default: "",
      type: String,
    },
    content: {
      default: "",
      type: String,
    },
    name: {
      default: "",
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.doctor {
  flex: 1 0 280px;
  @apply p-4;
  @apply mb-4;

  @screen md {
    flex: 1 0 33.3%;
  }
}

.doctor__image {
  @apply mb-4;

  @screen md {
    @apply mb-12;
  }
}

.doctor__content {
}

.doctor__name {
  @apply font-serif;
  @apply italic;
  @apply font-bold;
  @apply mb-4;
}
</style>
