<template>
    <div class="text-media"  :class="{'text-media--space' : space}">
        <div class="text-media__inner" :class="{'text-media__inner--reverse' : reverse}">
            <div class="text-media__image">
                <picture>
                    <img loading="lazy" :src="image" alt=""/>
                </picture>
            </div>
            <div class="text-media__content section" :class="{'text-media__content--space' : space, 'text-media__content--blue' : blue}">
                <div class="container">
                    <headline-component :headline="headline" :subline="subline"></headline-component>
                    <div class="text-media__text" v-html="content"></div>
                    <button-component v-if="link" :href="link">Mehr erfahren</button-component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeadlineComponent from './HeadlineComponent.vue'
    export default {
    components: { HeadlineComponent},
        props: {
            image: {
                default: '',
                type: String
            },
            headline: {
                default: '',
                type: String
            },
            subline: {
                default: '',
                type: String
            },
            content: {
                default: '',
                type: String
            },
            link: {
                default: '',
                type: String
            },
            space: {
                default: false,
                type: Boolean
            },
            blue: {
                default: false,
                type: Boolean
            },
            reverse: {
                default: false,
                type: Boolean
            },
            list: {
                default: () => [],
                type: Array
            }
        }
    }
</script>

<style lang="scss" scoped>
    .text-media {

        &--space {
            padding: clamp(30px, 13vw, 150px) 0 0 0;

            @screen md {
                padding: clamp(30px, 13vw, 150px) 0;
            }
        }
    }

    .text-media__inner {
        
        @screen md {
            @apply flex;
            @apply flex-wrap;

            &--reverse {
                @apply flex-row-reverse;
            }
        }
    }

    .text-media__content {
        @apply bg-grey;
        @apply text-center;

        @screen md {
            @apply w-1/2;
        }

        &--space {
            @apply bg-transparent;
            @apply text-left;
        }

        &--blue {
            @apply bg-blue;
            @apply text-white;
        }
    }

    .text-media__image {
        @apply relative;
        height: 56.25vw;
        
        @screen md {
            @apply w-1/2;
            @apply h-auto;
        }

        picture {
            
            @screen md {
                @apply absolute;
                @apply inset-0;
            }
        }

        img {
            @apply w-full;
            @apply h-full;
            @apply object-cover;
        }
    }
</style>