<template>
    <div class="text-on-image section">
        <div class="text-on-image__image">
            <picture>
                <img loading="lazy" :src="image" alt="">
            </picture>
        </div>
        <div class="text-on-image__inner">
            <headline-component :headline="headline" :subline="subline"></headline-component>
            <div class="text-on-image__content" v-html="content"></div>
            <button-component :href="link">Mehr erfahren</button-component>
        </div>
    </div>
</template>

<script>
import ButtonComponent from './ButtonComponent.vue'
import HeadlineComponent from './HeadlineComponent.vue'
    export default {
  components: { HeadlineComponent, ButtonComponent },
        props: {
            image: {
                default: '',
                type: String
            },
            headline: {
                default: '',
                type: String
            },
            subline: {
                default: '',
                type: String
            },
            content: {
                default: '',
                type: String
            },
            link: {
                default: '',
                type: String
            }
        }
    }
</script>

<style lang="scss" scoped>
    .text-on-image {
        @apply flex;
        @apply items-center;
        @apply justify-center;
        @apply text-center;
        @apply px-4;
    }

    .text-on-image__image {
        @apply absolute;
        @apply inset-0;
        z-index: -1;

        img {
            @apply w-full;
            @apply h-full;
            @apply object-cover;
        }
    }

    .text-on-image__inner {
        @apply bg-white;
        --bg-opacity: .9;
        @apply p-4;
        @apply max-w-5xl;

        @screen md {
            @apply p-12;
        }
    }
</style>