<template>
  <section
    class="image-component"
    :class="{
      'section--no-spacing-top': noSpacingTop === '1',
      'section--no-spacing-bottom': noSpacingBottom === '1',
    }"
    :style="`--background-color: ${background}`"
  >
    <div class="container">
      <div class="image-component__image">
        <picture>
          <img loading="lazy" :src="image" alt="" />
        </picture>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    image: {
      default: "",
      type: String,
    },
    background: {
      default: "#ffffff",
      type: String,
    },
    noSpacingTop: {
      default: "",
      type: String,
    },
    noSpacingBottom: {
      default: "",
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.image-component {
  background: var(--background-color);
}

.image-component__image {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
