<template>
    <div class="stage" :class="{'stage--dark' : dark}">
        <div v-if="image" class="stage__image">
            <picture>
                <img loading="lazy" :src="image" alt="">
            </picture>
        </div>
        <div class="stage__content">
            <headline-component level="h1"  :headline="headline" :subline="subline"></headline-component>
            <div class="stage__text" v-html="content"></div>
        </div>
    </div>
</template>

<script>
    import HeadlineComponent from './HeadlineComponent.vue'
    export default {
        components: {HeadlineComponent},
        props: {
            image: {
                default: '',
                type: String
            },
            headline: {
                default: '',
                type: String
            },
            subline: {
                default: '',
                type: String
            },
            content: {
                default: '',
                type: String
            },
            dark: {
                default: false,
                type: Boolean
            }
        }
    }
</script>

<style lang="scss" scoped>
    .stage {
        padding: clamp(30px, 13vw, 150px) 1rem;
        @apply relative;
        @apply bg-blue;
        @apply text-white;

        &--dark {
            @apply text-black;
        }
    }

    .stage__image {
        @apply absolute;
        @apply inset-0;

        picture {
            @apply w-full;
            @apply h-full;
        }

        img {
            @apply w-full;
            @apply h-full;
            @apply object-cover;
        }
    }

    .stage__content {
        @apply text-center;
        @apply max-w-xl;
        @apply mx-auto;
        @apply relative;
        @apply z-20;
    }
</style>