<template>
  <div class="hero">
    <div class="hero__image">
      <picture>
        <img loading="lazy" :src="image" alt="" />
      </picture>
    </div>
    <div class="hero__content section">
      <div class="container">
        <headline-component
          level="h1"
          :headline="headline"
          :subline="subline"
        ></headline-component>
        <div class="hero__text" v-html="heroText"></div>
      </div>
    </div>
    <div class="hero__infobox section">
      <div class="container">
        <headline-component
          class=""
          :headline="infoHeadline"
          :subline="infoSubline"
        ></headline-component>
        <div class="hero__infotext" v-html="infoText"></div>
        <button-component v-if="linkFirst" :href="linkFirst">{{
          linktextFirst
        }}</button-component>
        <button-component v-if="linkSecond" :href="linkSecond" light>{{
          linktextSecond
        }}</button-component>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "./ButtonComponent.vue";
import HeadlineComponent from "./HeadlineComponent.vue";
export default {
  components: { HeadlineComponent, ButtonComponent },
  props: {
    heroText: {
      default: "",
      type: String,
    },
    infoText: {
      default: "",
      type: String,
    },
    image: {
      default: "",
      type: String,
    },
    headline: {
      default: "",
      type: String,
    },
    subline: {
      default: "",
      type: String,
    },
    infoHeadline: {
      default: "",
      type: String,
    },
    infoSubline: {
      default: "",
      type: String,
    },
    linkFirst: {
      default: "",
      type: String,
    },
    linkSecond: {
      default: "",
      type: String,
    },
    linktextFirst: {
      default: "",
      type: String,
    },
    linktextSecond: {
      default: "",
      type: String,
    },
  },
};
</script>

<style lang="scss">
.hero {
  @apply block;
  grid-template-rows: 40vh auto;
  @apply grid-cols-3;

  @screen md {
    @apply grid;
  }
}

.hero__image {
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  height: 40vh;

  picture {
    height: 40vh;
    @apply block;
    @apply w-full;
    @apply h-full;
  }

  img {
    @apply w-full;
    @apply h-full;
    @apply object-cover;
  }
}

.hero__content {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 2;
  grid-row-end: span 1;
  // @apply py-8;
}

.hero__infobox {
  @apply bg-blue;
  grid-column-start: 3;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 2;
  @apply text-white;
  // @apply py-8;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply text-center;

  .container {
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-center;
  }

  .btn + .btn {
    @apply mt-12;
  }
}

.hero__infotext {
  @apply text-sm;
  @apply mb-8;
}
</style>
