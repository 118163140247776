<template>
  <section
    class="text"
    :class="{
      'section--no-spacing-top': noSpacingTop === '1',
      'section--no-spacing-bottom': noSpacingBottom === '1',
    }"
  >
    <div class="container">
      <div class="text__inner">
        <headline-component
          :headline="headline"
          :subline="subline"
        ></headline-component>
        <div class="text__text" v-html="content"></div>
        <button-component v-if="link" :href="link"
          >Mehr erfahren</button-component
        >
      </div>
    </div>
  </section>
</template>

<script>
import HeadlineComponent from "./HeadlineComponent.vue";
export default {
  components: { HeadlineComponent },
  props: {
    headline: {
      default: "",
      type: String,
    },
    subline: {
      default: "",
      type: String,
    },
    content: {
      default: "",
      type: String,
    },
    link: {
      default: "",
      type: String,
    },
    noSpacingTop: {
      default: "",
      type: String,
    },
    noSpacingBottom: {
      default: "",
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.text__inner {
  @apply max-w-6xl;
  @apply mx-auto;
}

.text__text {
  h3 {
    @apply mb-4;
  }
}
</style>
