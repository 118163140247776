<template>
  <div class="accordion">
    <div class="row">
      <div class="accordion__header" @click="toggleBody()">
        <div class="accordion__headline">{{ headline }}</div>
        <div class="accordion__icon">
          <div class="icon-arrowright" :class="{'accordion__icon--active' : bodyOpen}"></div>
        </div>
      </div>
      <div class="accordion__body" :class="{'accordion__body--open' : bodyOpen}">
        <div class="accordion__body-inner" v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadlineComponent from './HeadlineComponent.vue'
export default {
  name: 'AccordionItemComponent',
  components: { HeadlineComponent },
  props: {
    headline: {
      default: '',
      type: String
    },
    content: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      bodyOpen: false
    }
  },
  mounted () {
    this.$eventHub.$on('toggleAccordion', () => {
      this.bodyOpen = false
    })
  },
  methods: {
    toggleBody () {
      if (this.bodyOpen) {
        this.bodyOpen = !this.bodyOpen
      } else {
        this.$eventHub.$emit('toggleAccordion')
        this.bodyOpen = !this.bodyOpen
      }
    }
  }
}
</script>

<style lang="scss">
  .accordion {
    @apply mb-4;
  }

  .accordion__header {
    @apply bg-blue;
    @apply p-4;
    @apply cursor-pointer;
    @apply w-full;
    @apply flex;
    @apply flex-wrap;
    @apply items-center;
    @apply justify-between;
    @apply text-white;
    @apply font-bold;
    @apply text-lg;

    .accordion__header {
      @apply mb-0;
      flex: 1 auto;
    }
  }

  .accordion__icon {
    @apply w-6;

    div {
      transform: rotate(90deg);
      transform-origin: center;
      transition: all 0.3s ease-in-out;
      @apply w-4;
      @apply h-4;

      &.accordion__icon--active {
        transform: rotate(-90deg);
      }
    }
  }

  .accordion__body {
    max-height: 0;
    @apply overflow-hidden;
    transition: all 0.3s ease-in-out;
    @apply text-sm;
    @apply w-full;

    &--open {
      max-height: 1000px;
      @apply overflow-auto;
    }
  }

  .accordion__body-inner {
    @apply p-4;
  }
</style>
