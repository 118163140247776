<template>
    <component :is="type" :href="href" class="btn" :class="{'btn--light' : light, 'btn--blue' : blue}" @click.native="buttonClicked()">
        <slot></slot>
    </component>
</template>

<script>
    export default {
       props: {
           type: {
               default: 'a',
               type: String
           },
           href: {
               default: '',
               type: String
           },
           light: {
               default: false,
               type: Boolean
           },
           blue: {
               default: false,
               type: Boolean
           }
       },
       methods: {
           buttonClicked() {
               this.$eventHub.$emit('triggerClick')
           }
       } 
    }
</script>

<style lang="scss">
    .btn {
        @apply bg-brown;
        @apply text-white;
        @apply font-serif;
        @apply italic;
        @apply px-4;
        @apply py-1;
        @apply inline-block;
        @apply text-sm;
        @apply mr-2;
        @apply mt-4;

        &--light {
            @apply bg-transparent;
            @apply text-brown;
            @apply border-l-2;
            @apply border-r-2;
            @apply border-brown;
        }

        &--blue {
            @apply bg-blue;
        }
    }
</style>