<template>
  <section class="team">
    <div class="container">
      <headline-component
        class="text-center"
        :headline="headline"
        :subline="subline"
      ></headline-component>
      <div v-if="doctors" class="team__members">
        <doctor-component
          v-for="(item, index) in doctors"
          :key="index"
          :image="item.image"
          :content="item.content"
          :name="item.name"
        ></doctor-component>
      </div>
    </div>
  </section>
</template>

<script>
import DoctorComponent from "./DoctorComponent.vue";
import HeadlineComponent from "./HeadlineComponent.vue";
export default {
  components: { HeadlineComponent, DoctorComponent },
  props: {
    headline: {
      default: "",
      type: String,
    },
    subline: {
      default: "",
      type: String,
    },
    doctors: {
      default: () => [],
      type: Array,
    },
  },
};
</script>

<style lang="scss">
.team {
  @apply bg-grey;
}

.team__members {
  @apply flex;
  @apply flex-wrap;
  @apply w-full;
  @apply justify-between;
}
</style>
