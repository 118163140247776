<template>
  <header class="header" :class="`header--${scrollDirection}`">
    <div class="header__inner">
      <div class="header__logo">
        <logo></logo>
      </div>
      <div class="header__interact">
        <a href="tel:0523188210" class="header__phone">
          <span class="icon-phone header__icon"></span>
          <span class="font-serif italic font-bold mr-2 header__interact-link"
            >Telefon</span
          >
          <span class="header__interact-link">05231 88210</span>
        </a>
        <a class="header__mail" href="mailto:info@die-docs.de">
          <span class="icon-envelope header__icon"></span>
          <span class="font-serif italic font-bold header__interact-link"
            >E-Mail schreiben</span
          >
        </a>
        <div class="header__opening-hours" @click="toggleOpeningHours()">
          <span class="icon-clock header__icon"></span>
          <span class="header__interact-link">Sprechzeiten</span>
        </div>
        <slot></slot>
      </div>
    </div>
    <div class="modal-hours" :class="{ 'modal-hours--active': modalOpen }">
      <div
        class="modal-hours__inner"
        :class="{ 'modal-hours__inner--active': modalOpen }"
      >
        <headline-component
          level="h4"
          headline="Unsere Sprechzeiten sind wie folgt:"
        ></headline-component>

        <div class="modal-hours__table" v-html="table"></div>

        <p>
          Weitere Termine sind nach <i>telefonischer Vereinbarung</i> möglich.
          <br />Bitte beachten Sie dazu unsere Öffnungszeiten.
        </p>
      </div>
    </div>
  </header>
</template>

<script>
import HeadlineComponent from "./HeadlineComponent.vue";
import Logo from "./Logo.vue";
import NavComponent from "./NavComponent.vue";
export default {
  components: { Logo, NavComponent, HeadlineComponent },
  data() {
    return {
      modalOpen: false,
      scrollDirection: "",
    };
  },
  props: {
    table: {
      default: "",
      type: String,
    },
  },
  methods: {
    toggleOpeningHours() {
      this.modalOpen = !this.modalOpen;
      this.$eventHub.$emit("hoursOpen");
    },
  },
  mounted() {
    let scrollPos = 0;
    this.$eventHub.$on("navOpen", () => {
      this.modalOpen = false;
    });
    this.$eventHub.$on("triggerClick", () => {
      this.modalOpen = false;
    });

    window.addEventListener("scroll", () => {
      this.$eventHub.$emit("scrolling");

      if (window.pageYOffset > scrollPos && window.pageYOffset > 200) {
        this.scrollDirection = "down";
        scrollPos = window.pageYOffset;
      } else {
        scrollPos = window.pageYOffset;
        this.scrollDirection = "up";
      }
    });

    // this.$eventHub.$on("scrolling", () => {
    //   this.modalOpen = false;
    // });
  },
};
</script>

<style lang="scss">
.header {
  // @apply py-3;
  @apply sticky;
  @apply top-0;
  @apply z-30;
  @apply bg-white;
  transition: all 0.3s ease-in-out;

  &--down {
    transform: translate3d(0, -200%, 0);
  }
}

.header__inner {
  @apply flex;
  @apply justify-between;
  @apply items-center;
  padding-left: 5vw;
}

.header__interact {
  @apply flex;
  @apply text-sm;

  > a {
    @apply flex;
    @apply items-center;
    @apply mr-2;

    @screen md {
      @apply mr-4;
    }
  }
}

.header__icon {
  @apply mr-2;
  @apply text-base;
}

.header__interact-link {
  @apply hidden;

  @screen md {
    @apply inline-block;
  }
}

.header__opening-hours {
  @apply flex;
  @apply items-center;
  @apply font-bold;
  @apply font-serif;
  @apply italic;
  @apply bg-grey;
  @apply p-4;
  @apply cursor-pointer;
  transition: all 0.3s ease-in-out;

  @screen md {
    @apply p-8;
  }
}

.modal-hours {
  @apply bg-grey;
  @apply absolute;
  top: 100%;
  right: 0;
  left: auto;
  @apply z-30;
  height: calc(100vh - 62px);
  width: 100vw;
  @apply block;
  @apply items-center;
  max-height: 0;
  @apply overflow-auto;
  transition: all 0.2s ease-in-out;

  @screen md {
    height: calc(100vh - 94px);
    width: 50vw;
  }

  &--active {
    max-height: 100vh;
  }
}

.modal-hours__inner {
  @apply p-4;
  @apply opacity-0;
  transition: all 0.5s ease-in-out;

  @screen md {
    @apply p-12;
  }

  &--active {
    @apply opacity-100;
  }
}

.modal-hours__table {
  @apply py-12;
  @apply w-full;
  @apply overflow-scroll;
}
</style>
