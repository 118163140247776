import Vue from "vue";

Vue.prototype.$eventHub = new Vue();

import AccordionComponent from "./AccordionComponent.vue";
import AccordionItemComponent from "./AccordionItemComponent.vue";
import ButtonComponent from "./ButtonComponent.vue";
import DoctorComponent from "./DoctorComponent.vue";
import FooterComponent from "./FooterComponent.vue";
import HeaderComponent from "./HeaderComponent.vue";
import HeadlineComponent from "./HeadlineComponent.vue";
import HeroComponent from "./HeroComponent.vue";
import Logo from "./Logo.vue";
import NavComponent from "./NavComponent.vue";
import StageComponent from "./StageComponent.vue";
import TeamComponent from "./TeamComponent.vue";
import TextComponent from "./TextComponent.vue";
import TextMediaComponent from "./TextMediaComponent.vue";
import TextOnImageComponent from "./TextOnImageComponent.vue";
import TestComponent from "./testComponent.vue";
import ImageComponent from "./ImageComponent.vue";

Vue.component("accordion-component", AccordionComponent);
Vue.component("accordion-item-component", AccordionItemComponent);
Vue.component("button-component", ButtonComponent);
Vue.component("doctor-component", DoctorComponent);
Vue.component("footer-component", FooterComponent);
Vue.component("header-component", HeaderComponent);
Vue.component("headline-component", HeadlineComponent);
Vue.component("hero-component", HeroComponent);
Vue.component("logo", Logo);
Vue.component("nav-component", NavComponent);
Vue.component("stage-component", StageComponent);
Vue.component("team-component", TeamComponent);
Vue.component("text-component", TextComponent);
Vue.component("text-media-component", TextMediaComponent);
Vue.component("text-on-image-component", TextOnImageComponent);
Vue.component("image-component", ImageComponent);

Vue.component("test-component", TestComponent);

new Vue({
  el: "#app",
});
