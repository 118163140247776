<template>
    <div class="headline">
        <div v-if="subline" class="subline">{{subline}}</div>
        <component :is="level" :class="styleLevel">
            {{headline}}
        </component>
    </div>
</template>

<script>
    export default {
        props: {
            headline: {
                default: '',
                type: String
            },
            subline: {
                default: '',
                type: String
            },
            level: {
                default: 'h2',
                type: String
            },
            styleLevel: {
                default: '',
                type: String
            }
        }
    }
</script>

<style lang="scss">

</style>