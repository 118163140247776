<template>
  <section
    class=""
    :class="{
      'section--no-spacing-top': noSpacingTop === '1',
      'section--no-spacing-bottom': noSpacingBottom === '1',
    }"
  >
    <div class="container">
      <div class="accordion">
        <headline-component :headline="headline"></headline-component>
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
import HeadlineComponent from "./HeadlineComponent.vue";

export default {
  name: "AccordionComponent",
  components: {
    HeadlineComponent,
  },
  props: {
    headline: {
      default: "",
      type: String,
    },
    accordionItems: {
      default: () => [],
      type: Array,
    },
    noSpacingTop: {
      default: "",
      type: String,
    },
    noSpacingBottom: {
      default: "",
      type: String,
    },
  },
};
</script>

<style lang="scss">
.accordion {
  @apply max-w-6xl;
  @apply mx-auto;
}
</style>
